import axios from 'axios';
import { format, formatDistance, getTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import Cookies from 'js-cookie';
import Swal, {
    SweetAlertCustomClass,
    SweetAlertIcon,
    SweetAlertPosition,
    SweetAlertResult
} from 'sweetalert2';
import * as yup from 'yup';
import index from './Store';
import { setTableRowLimit } from './Store/actions';
import { notificationTypes } from './components/UIComponents/Header/constants';
import { api_prefix, apiToken, isTokenBasedAuthentication, minTableRowHeight } from './config';
import { constantStrings } from './constantStrings';
import {
    administrationPermissionApprovalTabs,
    forbiddenErrorReachOutUrl,
    modulePermissions
} from './constants';
import i18n from 'i18next';
import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import { domPurifyConfig } from './constants';

const customIcons = {
    success:
        '<svg id="check-mark" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><g id="Group_30646" data-name="Group 30646" transform="translate(0 0)"><path id="Path_65977" data-name="Path 65977" d="M54.626,54.626a32,32,0,1,0-45.253,0A31.994,31.994,0,0,0,54.626,54.626Zm-34.1-27.517,7.2,7.2,15.763-15.75,5.577,5.577-15.75,15.75-5.59,5.577-5.577-5.577-7.2-7.2Z" transform="translate(0 0)" fill="#2d5fd8"/></g></svg>',
    info: '<svg id="warning" xmlns="http://www.w3.org/2000/svg" width="64" height="60.31" viewBox="0 0 64 60.31"><path id="Path_65978" data-name="Path 65978" d="M62.67,60.908,40.062,19.348a9.376,9.376,0,0,0-16.124,0L1.331,60.908a9.371,9.371,0,0,0,8.06,14.161H54.608A9.372,9.372,0,0,0,62.67,60.908ZM32,67.569a3.75,3.75,0,1,1,3.75-3.75A3.755,3.755,0,0,1,32,67.569Zm3.75-15a3.75,3.75,0,0,1-7.5,0V33.819a3.75,3.75,0,0,1,7.5,0Z" transform="translate(0 -14.759)" fill="#e04f5f"/></svg>',
    warning:
        '<svg id="close" xmlns="http://www.w3.org/2000/svg" width="64" height="63.958" viewBox="0 0 64 63.958"><ellipse id="Ellipse_1040" data-name="Ellipse 1040" cx="32" cy="31.979" rx="32" ry="31.979" transform="translate(0 0)" fill="#e04f5f"/><g id="Group_30647" data-name="Group 30647" transform="translate(57.395 31.979) rotate(135)"><rect id="Rectangle_9097" data-name="Rectangle 9097" width="6.848" height="35.121" transform="translate(14.467 1.385)" fill="#fff"/> <rect id="Rectangle_9098" data-name="Rectangle 9098" width="35.121" height="6.848" transform="translate(1.385 14.467)" fill="#fff"/></g></svg>',
    error: '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><g id="sad" transform="translate(0.128 0.129)"><circle id="Ellipse_1041" data-name="Ellipse 1041" cx="32" cy="32" r="32" transform="translate(-0.128 -0.129)" fill="#e04f5f"/><g id="Group_30651" data-name="Group 30651" transform="translate(11.872 15.451)"><path id="Path_65979" data-name="Path 65979" d="M163.531,328.565a1.855,1.855,0,0,1-1.308-.539,15.129,15.129,0,0,0-20.9,0,1.847,1.847,0,0,1-2.612-2.612,18.47,18.47,0,0,1,26.127,0,1.849,1.849,0,0,1,0,2.612A1.825,1.825,0,0,1,163.531,328.565Z" transform="translate(-131.774 -296.124)" fill="#fff"/><circle id="Ellipse_1042" data-name="Ellipse 1042" cx="5" cy="5" r="5" transform="translate(0 1)" fill="#fff"/><circle id="Ellipse_1043" data-name="Ellipse 1043" cx="5" cy="5" r="5" transform="translate(30 1)" fill="#fff"/></g></g></svg>'
};

interface certificationLevel {
    name: string;
}
interface showPopUpInterface {
    titleText?: string | null;
    text?: string | null;
    type?: SweetAlertIcon | any;
    confirmButtonText?: string | null;
    showCloseButton?: boolean;
    noCustomHeader?: boolean;
    isBtnDanger?: boolean;
    successText?: string | null;
    showCancelButton?: boolean;
    cancelButtonText?: string | null;
    showConfirmButton?: boolean;
    action?: any;
    toast?: boolean;
    customClass?: SweetAlertCustomClass;
}
export const getOrderedData = (unorderedList: certificationLevel[], orderedNames: string[]) => {
    return orderedNames.reduce((result, name) => {
        const item = unorderedList.find((item) => item.name === name);
        if (item) {
            result.push({ ...item, name: i18n.t(item.name) });
        }
        return result;
    }, [] as certificationLevel[]);
};

export const getCustomSanitizeHTML = (content: any, config = domPurifyConfig) =>
    DOMPurify.sanitize(draftToHtml(JSON.parse(content)), config);

export const getDefaultRedirectTab = () => {
    const userInfo = index.getState().CurrentUser.currentUserData;
    if (userInfo.module_permissions.includes(modulePermissions.overviewDashboard)) {
        return '/OverviewDashboard';
    }
    if (userInfo.module_permissions.includes(modulePermissions.verificationVerifierDashboard)) {
        return '/Verification';
    }
    return '/Home';
};

export const getProfilePicture = (id: number) => {
    return id ? `${api_prefix}/users/${id}/get_file/?field=profile_picture` : '';
};

export const getTimeStamp = (date: any, timeZone: any, basedOnDay = false) => {
    const currentDateTime = formatInTimeZone(new Date(), `${timeZone}`, 'Pp');
    const milliseconds = basedOnDay ? 8.64e7 : 3600000;
    return getTime(new Date(currentDateTime)) - getTime(new Date(date)) > milliseconds
        ? format(new Date(date), 'PPpp')
        : `${formatDistance(
              new Date(currentDateTime),
              new Date(format(new Date(date), 'Pp'))
          )} ${i18n.t(constantStrings.ago)}`;
};

export const getLabelValuePairs = (list = []) =>
    list.map((item: any) => ({ value: item.name, label: item.name }));

export const getRevertedStatus = (status: string) => status === constantStrings.reverted;

export const getHeaders = () => {
    const token = Cookies.get('csrftoken');
    const headers = new Headers();
    if (token) headers.set('X-CSRFToken', token);
    if (isTokenBasedAuthentication && apiToken) headers.set('Authorization', `Bearer ${apiToken}`);
    return headers;
};

export const showPopUp = (props: showPopUpInterface): Promise<SweetAlertResult | void> => {
    const {
        titleText,
        text,
        type,
        confirmButtonText = `${i18n.t(constantStrings.ok)}`,
        showConfirmButton = true,
        showCloseButton,
        noCustomHeader,
        isBtnDanger,
        successText = '',
        showCancelButton,
        cancelButtonText = `${i18n.t(constantStrings.cancel)}`,
        action,
        toast = false,
        customClass
    } = props;
    const customHtml =
        (titleText && titleText ? `<div class="mb-3"><b>${i18n.t(titleText)}</b></div>` : '') +
        (text ? `<p>${i18n.t(text)}</p>` : '');

    const customHeader = {
        success: `<b class='popup-title-success'>${i18n.t(successText || '')}</b>`,
        info: `<b class='popup-title-error'>${`${i18n.t(constantStrings.warning)}!`}</b>`,
        warning: `<b class='popup-title-error'>${`${i18n.t(constantStrings.whops)}!`}</b>`,
        error: `<b class='popup-title-error'>${i18n.t(constantStrings.error)}</b>`
    };

    const position: SweetAlertPosition = toast ? 'bottom-end' : 'center';

    const data = {
        toast,
        position,
        //@ts-ignore
        title: noCustomHeader ? '' : customHeader[String(type)],
        html: customHtml,
        showCloseButton: showCloseButton,
        allowOutsideClick: false,
        width: toast ? 360 : 400,
        padding: '1rem',
        customClass: customClass || {
            popup: 'sweetalert-modal',
            title: 'py-0',
            htmlContainer: 'popup-html-container mx-0',
            actions: 'm-0 mt-2',
            confirmButton: 'popup-btn ' + (isBtnDanger ? 'popup-btn-error' : 'popup-btn-primary'),
            closeButton: 'text-secondary rounded-circle',
            icon: 'border-0 m-auto',
            cancelButton: 'popup-btn ' + (isBtnDanger ? '' : 'popup-btn-secondary')
        },
        buttonsStyling: false,
        icon: type || '',
        //@ts-ignore
        iconHtml: !toast && customIcons[String(type)],
        confirmButtonText: confirmButtonText || '',
        showConfirmButton: showConfirmButton,
        showCancelButton: showCancelButton,
        cancelButtonText: cancelButtonText || '',
        timer: toast ? 3000 : undefined,
        timerProgressBar: toast
    };
    if (action) {
        return Swal.fire(data).then((result) => {
            if (result.value) action();
        });
    }
    return Swal.fire(data);
};

export const isKeyEnteredAllowedForInputTypeNumber = (e: any) => {
    if (
        e.key &&
        (/^[-0-9.]+$/.test(e.key) ||
            e.which === 8 ||
            (e.which > 36 && e.which < 41) ||
            e.which === 9)
    ) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
};

export function validateYupSchema(
    type: string,
    name: string,
    required = true,
    params: {
        minNumber?: number;
        maxNumber?: number;
        maxChars?: number;
    } = {
        minNumber: Number.MIN_SAFE_INTEGER,
        maxNumber: Number.MAX_SAFE_INTEGER,
        maxChars: Number.MAX_SAFE_INTEGER
    },
    fileParams?: {
        size: {
            fileSize: number;
            message: string;
        } | null;
        format: {
            fileType: string[];
            message: string;
        } | null;
    }
): any {
    function func(data: any): typeof yup {
        return required ? data.required(`${name} ${i18n.t(constantStrings.isRequired)}`) : data;
    }

    function validate() {
        const { minNumber, maxNumber } = params;
        const numberValidation = () => {
            const validation =
                params &&
                yup
                    .number()
                    .transform((transformValue: any, originalValue: any) =>
                        originalValue === '' ? null : transformValue
                    )
                    .nullable();
            if (minNumber) {
                validation.min(
                    minNumber,
                    `${name} ${i18n.t(constantStrings.mustBeGreaterThanOrEqualTo)} ${minNumber}`
                );
            }
            if (maxNumber) {
                validation.max(
                    maxNumber,
                    `${name} ${i18n.t(constantStrings.mustBeLessThanOrEqualTo)} ${maxNumber}`
                );
            }
            return validation.typeError(`${name} ${i18n.t(constantStrings.mustBeANumber)}`);
        };

        switch (type) {
            case 'number':
                return func(numberValidation());
            case 'integer':
                return func(
                    numberValidation().integer(`${name} ${i18n.t(constantStrings.mustBeAnInteger)}`)
                );
            case 'alphaNumericText':
                return func(
                    yup
                        .string()
                        .matches(/^[-_ a-zA-Z0-9]+$/, {
                            message: i18n.t(constantStrings.pleaseEnterAlphabetsNumbers),
                            excludeEmptyString: true
                        })
                        .nullable()
                );
            case 'onlyAlphabets':
                return func(
                    yup.string().matches(/^[a-zA-z ]+$/, {
                        message: i18n.t(constantStrings.pleaseEnterAlphabetsOnly),
                        excludeEmptyString: true
                    })
                );
            case 'text':
                return func(yup.string().trim().nullable());
            case 'textArea':
                const { maxChars } = params;
                let textAreaValidation = yup.string();
                if (maxChars) {
                    textAreaValidation.max(
                        maxChars,
                        `${i18n.t(constantStrings.exceededCharacterLimit)}`
                    );
                }
                return func(textAreaValidation);
            case 'password':
                return yup
                    .string()
                    .required(`${i18n.t(constantStrings.newPasswordIsRequired)}`)
                    .min(8, `${i18n.t(constantStrings.passwordShouldHaveMinimum8Characters)}`)
                    .max(16, `${i18n.t(constantStrings.passwordCanOnlyHaveMax)}`)
                    .matches(
                        /(?=.*[a-z])/,
                        `${i18n.t(constantStrings.passwordShouldHaveAtleastOneLowerCase)}`
                    )
                    .matches(
                        /(?=.*[A-Z])/,
                        `${i18n.t(constantStrings.passwordShouldHaveAtleastOneUpperCase)}`
                    )
                    .matches(
                        /(?=.*\d)/,
                        `${i18n.t(constantStrings.passwordShouldHaveAtleastOneNumber)}`
                    )
                    .matches(
                        /(.*\W.*)/,
                        `${i18n.t(constantStrings.passwordMustHaveOneSpecialCharacter)}`
                    );
            case 'email':
                return func(yup.string().email().nullable());
            case 'date':
            case 'time':
            case 'dateTimePicker':
            case 'datePicker':
            case 'timePicker':
                return func(yup.date().nullable());
            case 'select':
                return func(yup.object().nullable());
            case 'phone':
                return func(
                    yup.string().matches(/^[0-9]{3}-\d{3}-\d{4}$/, {
                        message: i18n.t(constantStrings.shouldFollowTheFormat),
                        excludeEmptyString: true
                    })
                );
            case 'startDate':
                return func(yup.date().nullable());
            case 'checkbox':
                return func(yup.bool());
            case 'array':
                return func(
                    yup
                        .array()
                        .test({
                            message: (value: any) => {
                                if (required && !value.length) {
                                    return `${name} ${i18n.t(constantStrings.isRequired)}`;
                                } else {
                                    return true;
                                }
                            },
                            test: (value: any) => {
                                if (required && !value?.length) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        })
                        .nullable()
                );
            case 'file':
                return yup.mixed().test({
                    message: (item: any) => {
                        if (required && !item.value.length) {
                            return `${name} ${i18n.t(constantStrings.isRequired)}`;
                        } else if (
                            fileParams &&
                            fileParams.size &&
                            item.value.length &&
                            item.value[0].size > fileParams.size.fileSize
                        ) {
                            return fileParams.size.message;
                        } else if (
                            fileParams &&
                            item.value.length &&
                            fileParams.format &&
                            !fileParams.format.fileType.includes(item.value[0].type)
                        ) {
                            return fileParams.format.message;
                        } else {
                            return false;
                        }
                    },
                    test: (value: any) => {
                        if (
                            (required && !value.length) ||
                            (fileParams &&
                                fileParams.size &&
                                value.length &&
                                value[0].size > fileParams.size.fileSize) ||
                            (fileParams &&
                                fileParams.format &&
                                value.length &&
                                !fileParams.format.fileType.includes(value[0].type))
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                });
            case 'url':
                return func(
                    yup
                        .string()
                        .matches(
                            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                            {
                                message: `${name} ${i18n.t(constantStrings.mustBeInCorrectFormat)}`,
                                excludeEmptyString: true
                            }
                        )
                );
            case 'radio':
                return func(yup.string().nullable());
            case 'disclaimer':
                return func(
                    yup.bool().oneOf([true], `${i18n.t(constantStrings.acceptTermsConditions)}`)
                );
            case 'dynamic_resolver':
                return;
            default:
                break;
        }
        return func(yup.string());
    }

    return validate();
}

export const clearLocalStorageAfterLogout = (withRefresh = true) => {
    localStorage.clear();
    Cookies.remove('csrftoken');
    Cookies.remove('newUserToken');
    Cookies.remove('sessionid');
    if (withRefresh) window.location.href = '/Login';
};

export const showSuccessPopup = (successMessage: string) => {
    return showPopUp({
        titleText: successMessage,
        type: 'success',
        successText: `${i18n.t(constantStrings.success)}`
    });
};

export const showSuccessToast = (successMessage: string) => {
    return showPopUp({
        toast: true,
        type: 'success',
        successText: successMessage,
        showConfirmButton: false,
        customClass: {
            title: 'mx-1',
            popup: 'mb-5'
        }
    });
};

export const onPatchSuccessCallback = (successMessage: string) => {
    return showPopUp({
        titleText: successMessage,
        type: 'success',
        successText: `${i18n.t(constantStrings.success)}`,
        action: () => {
            clearLocalStorageAfterLogout();
        },
        confirmButtonText: `${i18n.t(constantStrings.loginPage)}`
    });
};

const isLast = (value: any) => {
    return typeof value === 'string' || Array.isArray(value);
};

const getLast = (obj: any, msg: any) => {
    Object.entries(obj).forEach(([key, value]) => {
        if (isLast(value)) {
            msg = `${msg ? `${msg}\n` : ''}${
                isLast(key) && key !== '__all__' ? `<div>${key}: ${value}</div>` : ''
            }`;
        } else {
            msg = getLast(value, msg);
        }
    });
    return msg;
};

const getErrorResponse = (resp: any) => {
    if (typeof resp === 'string') {
        return resp + '<br/>';
    } else {
        if (Array.isArray(resp)) {
            let text = '';
            resp.forEach((value: any) => {
                if (typeof value === 'object') {
                    text = text + getLast(value, '');
                } else text = (text ? text + ', ' : '') + value;
            });
            return text + '<br/>';
        } else {
            return getLast(resp, '');
        }
    }
};

export const onErrorCallback = (error: any) => {
    let errorMessage: string = '',
        showErrorPopup = true,
        responseJSON: any = '';
    const resp = error.response || error.request || error.message;

    switch (resp.status) {
        case 401: {
            showErrorPopup = false;
            break;
        }
        case 403: {
            errorMessage = `${i18n.t(
                constantStrings.forbiddenErrorMessage
            )} <a href="mailto:${forbiddenErrorReachOutUrl}">${forbiddenErrorReachOutUrl}</a>.`;
            break;
        }
        case 500: {
            errorMessage = resp?.data?.message || i18n.t(constantStrings.internalServerError);
            break;
        }
        case 502: {
            errorMessage = i18n.t(constantStrings.badGateway);
            break;
        }
        case 503: {
            errorMessage = i18n.t(constantStrings.serviceUnavailable);
            break;
        }
        case 504: {
            errorMessage = i18n.t(constantStrings.APIGateway);
            break;
        }
        case 0: {
            errorMessage = i18n.t(constantStrings.unableToAccessTheServer);
            break;
        }
        default: {
            const typeOfResponseJSON = typeof resp.responseJSON;
            if (typeof resp === 'string') {
                errorMessage = resp;
            } else if (typeOfResponseJSON === 'string' || typeOfResponseJSON === 'number') {
                errorMessage = resp.responseJSON;
            } else if (typeof resp.responseJSON === 'object' && resp.responseJSON.service_name) {
                errorMessage = `${resp.responseJSON.service_name} ${i18n.t(
                    constantStrings.isNotResponding
                )}`;
            } else {
                responseJSON = resp.responseJSON || resp.data;
                if (responseJSON) {
                    Object.values(responseJSON).forEach((item: any, index: number) => {
                        if (typeof item === 'string') {
                            errorMessage = (errorMessage ? errorMessage + '<br>' : '') + item;
                        } else {
                            errorMessage = getErrorResponse(item);
                        }
                    });
                }
            }
        }
    }
    if (showErrorPopup)
        showPopUp({
            titleText: errorMessage,
            type: 'error'
        });
};

export const getCurrentPath = () => {
    return window.location.pathname;
};

export function* getFileData(url: any, action: any): any {
    const json = yield axios
        .get(url, { params: action.params, responseType: 'blob' })
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            onErrorCallback(error);
            return error.response;
        });
    return json;
}

export function* getApiData(url: any, action?: any, showSuccessPopUp?: boolean): any {
    const json = yield axios
        .get(url, { params: action?.params })
        .then((response: any) => {
            if (showSuccessPopUp) showSuccessPopup(response.data?.message);
            return response.data;
        })
        .catch((error: any) => {
            onErrorCallback(error);
            return error.response;
        });
    return json;
}

export function* postApiData(
    url: any,
    action: any,
    successMessage: string,
    showPopUp: boolean,
    setShowLoader?: any,
    excludingErrorStatusCodes: Array<number> = [401]
): any {
    const json = yield axios
        .post(url, action.params, { withCredentials: true })
        .then((response: any) => {
            showPopUp && showSuccessPopup(successMessage);
            return response.data;
        })
        .catch((error: any) => {
            if (!excludingErrorStatusCodes.includes(error.response?.status)) {
                onErrorCallback(error);
            }
            return error.response;
        })
        .finally(() => {
            if (setShowLoader) setShowLoader(false);
        });
    return json;
}

export const uploadFileWithProgress = (
    url: string,
    action: any,
    successMessage: string,
    showPopUp: boolean
) => {
    const config = {
        onUploadProgress: action.onUploadProgress
    };
    const json = axios
        .post(url, action.params, config)
        .then((response: any) => {
            action.onUploadSuccess();
            showPopUp && showSuccessPopup(successMessage);
            return response.data;
        })
        .catch((error: any) => {
            if (error.response?.status !== 401) {
                onErrorCallback(error);
                action.onUploadSuccess();
            }
            return error.response;
        });
    return json;
};

export function* putApiData(
    url: any,
    action: any,
    successMessage: string,
    showPopUp: boolean
): any {
    const json = yield axios
        .put(url, { ...action.params })
        .then((response: any) => {
            showPopUp && showSuccessPopup(successMessage);
            return response.data;
        })
        .catch((error: any) => {
            onErrorCallback(error);
            return error.response;
        });
    return json;
}

export function* deleteApiData(url: any, action: any, successMessage: string): any {
    const json = yield axios
        .delete(url, { data: action.payload })
        .then((response: any) => {
            showSuccessPopup(successMessage);
            return response.data;
        })
        .catch((error: any) => onErrorCallback(error));
    return json;
}

export function* patchApiData(
    url: any,
    action: any,
    successMessage: string,
    showPopUp: boolean,
    login?: boolean
): any {
    const json = yield axios
        .patch(url, { ...action.params })
        .then((response: any) => {
            login && showPopUp
                ? onPatchSuccessCallback(successMessage)
                : successMessage && showSuccessPopup(successMessage);
            return response.data;
        })
        .catch((error: any) => {
            onErrorCallback(error);
            return error.response;
        });
    return json;
}

export function* optionsApiData(url: any, action: any): any {
    const json = yield axios
        .options(url, { params: action.params })
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            onErrorCallback(error);
            return error.response;
        });
    return json;
}

export const companyTypeList = [
    {
        value: 'Property Management',
        label: i18n.t(constantStrings.propertyManagement)
    },
    {
        value: 'Property Owner',
        label: i18n.t(constantStrings.propertyOwner)
    },
    { value: 'External Consultant', label: i18n.t(constantStrings.externalConsultant) },
    { value: 'Verifier', label: i18n.t(constantStrings.verifier) }
];

export const secondaryCompanyTypeList = [
    {
        value: 'Property Management',
        label: i18n.t(constantStrings.propertyManagement)
    },
    { value: 'External Consultant', label: i18n.t(constantStrings.externalConsultant) }
];

export const jobTitleList = [{ value: 'admin', label: i18n.t(constantStrings.admin) }];

// Energy Star Utilites
export const parseOptionsToSelect = (optionsData: Array<any>) =>
    optionsData.map((element: any) => ({
        label: element.display_name,
        value: element.value
    }));

export const booleanOptions = [
    { label: i18n.t(constantStrings.yes), value: true },
    { label: i18n.t(constantStrings.no), value: false }
];

export const possibleVolumeOptions = [
    i18n.t(constantStrings.cubicYards),
    i18n.t(constantStrings.cubicMeters),
    i18n.t(constantStrings.gallonsUS),
    i18n.t(constantStrings.gallonsUK),
    i18n.t(constantStrings.liters)
];

export const numberOfBuildingsOptions = [
    { label: i18n.t(constantStrings.none), value: 'None' },
    { label: i18n.t(constantStrings.one), value: 'One' },
    { label: i18n.t(constantStrings.moreThanOne), value: 'More Than One' }
];

export const parseValueToSelect = (valueData: Array<string>) =>
    valueData?.map((element: any) => ({
        label: element,
        value: element
    }));

export const quarterPercentagesOptions = (): Array<any> => [
    { label: 0, value: 0 },
    { label: 25, value: 25 },
    { label: 50, value: 50 },
    { label: 75, value: 75 },
    { label: 100, value: 100 }
];

export const calculatePercentage = (num1: any, num2: any) =>
    ((Math.abs(num1 - num2) / num2) * 100).toFixed(2);

export const RECOwnerOptions = [
    { label: i18n.t(constantStrings.owned), value: 'Owned' },
    { label: i18n.t(constantStrings.sold), value: 'Sold' },
    { label: i18n.t(constantStrings.arbitrage), value: 'Arbitrage' }
];

export const getBase64FromFile = (file: any) => {
    return new Promise((resolve) => {
        let baseURL: any = '';
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

export const openFileInNewTab = (url: any, setLoader?: Function) => {
    if (setLoader) setLoader(true);
    let fileUrl = url;
    let config = {
        responseType: 'blob',
        headers: { ...getHeaders() }
    };

    //@ts-ignore
    //prettier-ignore
    axios.get(fileUrl, config)
        .then((response) => {
            const file = new Blob([response.data], { type: response.data.type });
            const url = window.URL.createObjectURL(file);
            let tab = window.open();
            //@ts-ignore
            tab.location.href = url;
        })
        .finally(() => {
            if (setLoader) setLoader(false);
        });
};

export function fileUpload(
    uploadURL: string,
    file: any,
    onSuccessCallback?: Function,
    setLoader?: Function,
    onRequestComplete?: Function
) {
    if (setLoader) setLoader(true);
    const requestPayload = new FormData();
    requestPayload.append('file', file);
    axios
        .post(uploadURL, requestPayload)
        .then((res) => {
            showSuccessPopup(res.data.message);
            if (onSuccessCallback) onSuccessCallback(res);
        })
        .catch(onErrorCallback)
        .finally(() => {
            if (setLoader) setLoader(false);
            if (onRequestComplete) onRequestComplete();
        });
}

export function fileDownload(
    url: string,
    fileName?: string,
    onRequestComplete?: Function,
    setLoader?: Function
) {
    if (setLoader) setLoader(true);
    return axios
        .get(`${url}`, {
            responseType: 'blob'
        })
        .then((response) => {
            const url = window.URL.createObjectURL(
                new Blob([response.data], { type: response.data.type })
            );
            const link = document.createElement('a');
            link.href = url;
            const donwloadFileName =
                fileName ?? response.headers['content-disposition'].split('filename=')[1];
            link.setAttribute('download', donwloadFileName);
            document.body.appendChild(link);
            link.click();
        })
        .catch(onErrorCallback)
        .finally(() => {
            if (onRequestComplete) onRequestComplete();
            if (setLoader) setLoader(false);
        });
}

export async function getImage(imageUrl: string, setImage: Function, setLoader: Function) {
    const headers = getHeaders();
    try {
        const response = await fetch(imageUrl, { headers });
        const blob = await response.blob();

        setImage(URL.createObjectURL(blob));
        setLoader(false);
    } catch (error) {
        setLoader(false);
        setImage(null);
    }
}

export const publishNowoptions = [
    { value: 'publishNow', label: i18n.t(constantStrings.publishNow) },
    { value: 'setExpiryDate', label: i18n.t(constantStrings.setupQuestionnaireExpiryDate) }
];

export const setGenericTableLimit = (elementId: string) => {
    if (document.getElementById(elementId)) {
        let limit: number = Math.floor(
            // @ts-ignore
            document.getElementById(elementId).clientHeight / minTableRowHeight + 10
        );
        //@ts-ignore
        index.dispatch(setTableRowLimit(limit));
    }
};

export const MIMEToFileExtension: any = {
    'application/pdf': '.pdf',
    'text/csv': '.csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'image/tif': '.tif',
    'image/jpeg': '.jpeg',
    'image/png': '.png',
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'text/plain': '.txt'
};

export const acceptedFileTypes = [
    'application/pdf',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/tif',
    'image/jpeg',
    'image/png',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'image/jpe'
];

export const allFileTypes = [
    '.pdf',
    '.csv',
    '.xlsx',
    '.tif',
    '.jpeg',
    '.png',
    '.doc',
    '.docx',
    '.txt',
    '.jpe'
].join(', ');

export const additionalFileTypes = ['.mp4', '.mpeg'].join(', ');

export const documentFileTypes = ['.xlsx', '.ods', '.csv'].join(', ');

export const imageFileTypes = ['.jpeg', '.png', '.jpe'].join(', ');

export const buildingAccessUserGroupList = [
    { value: 9, label: i18n.t(constantStrings.propertyUser) },
    { value: 10, label: i18n.t(constantStrings.propertyReadOnlyUser) }
];
export const scrollTop = () => {
    let element = document.getElementById('scroll');
    if (element) {
        element.scrollIntoView();
    }
};

export const formatContactNumber = (number: any) => {
    if (!number) return '';
    return number.toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const isNull = (value: any) => {
    return value === null;
};

export const getRandomNumberBetween = (min: any, max: any) =>
    Math.floor(Math.random() * (max - min + 1) + min);

export const filterList = (filterData: any) => {
    return Object.entries(filterData).reduce((arr: any, [key, valueArray]: any) => {
        const values = valueArray.flatMap(Object.values).join(',');
        arr[key] = values;
        return arr;
    }, {});
};

export const canAccessButton = (group: any, userGroups: any) => userGroups.includes(group);

export const canFeature = (feature: string) => {
    const featureFlags = index.getState().Waffle.featureFlags;
    return featureFlags[feature]?.is_active;
};

export const setUserLanguage = (userLanguage: string) => {
    i18n.changeLanguage(userLanguage);
    //@ts-ignore
    axios.defaults.headers['Accept-language'] = userLanguage;
};

export const handleNotificationRedirection = (data: any, history: any) => {
    if (data.notification_type !== 'New Signup') {
        switch (data.notification_type) {
            case notificationTypes.changeUserGroupRequest: {
                history.push('/Permission', {
                    defaultTab: administrationPermissionApprovalTabs.groupApproval
                });
                break;
            }
            case notificationTypes.changeUserSubRegionRequest: {
                history.push('/Permission', {
                    defaultTab: administrationPermissionApprovalTabs.subRegionApproval
                });
                break;
            }
            case notificationTypes.changeUserRegionRequest: {
                history.push('/Permission', {
                    defaultTab: administrationPermissionApprovalTabs.regionApproval
                });
                break;
            }
            case notificationTypes.buildingAccessRequest: {
                history.push('/Permission', {
                    defaultTab: administrationPermissionApprovalTabs.buildingApproval
                });
                break;
            }
            case notificationTypes.newUserAdded: {
                history.push('/Users');
                break;
            }
            case notificationTypes.verificationRequest: {
                history.push('/Assign');
                break;
            }
            case notificationTypes.newBuildingRegistration:
            case notificationTypes.buildingManagementCompanyChanged:
            case notificationTypes.buildingOwnerCompanyChanged: {
                history.push('/BuildingDetails', { tab: 'Documents', id: data.context.id });
                break;
            }
            case notificationTypes.questionnaireResponseReverted:
            case notificationTypes.revertedQuestionnaireResponsePending:
            case notificationTypes.newVersionQuestionnaireAvailable:
            case notificationTypes.certificationExpiryApproaching: {
                history.push('/BuildingDetails', {
                    tab: 'Questionnaire',
                    id: data.context.questionnaire_answering.building.id
                });
                break;
            }
            case notificationTypes.preSiteVerificationCompleted: {
                history.push('/BuildingDetails', {
                    tab: 'Questionnaire',
                    subTab: 'Historical',
                    id: data.context.questionnaire_answering.building.id
                });
                break;
            }
            case notificationTypes.questionnaireVersionExpire:
            case notificationTypes.newQuestionnaireVersionPublished:
            case notificationTypes.buildingRequestUpdate: {
                history.push('/BuildingList');
                break;
            }
            case notificationTypes.buildingCertificationCompleted: {
                history.push('/BuildingDetails', { tab: 'Certification', id: data.context.id });
                break;
            }

            case notificationTypes.verificationAssigned: {
                history.push('/Start');
                break;
            }
            case notificationTypes.bomaBestCertificationAwarded: {
                history.push('/BuildingDetails', {
                    tab: 'Certification',
                    id: data.context.questionnaire_answering.building.id
                });
                break;
            }
            case notificationTypes.secondaryVerification: {
                history.push('/Start', {
                    tab: 'Secondary'
                });
                break;
            }
            case notificationTypes.verificationScheduleCreated: {
                history.push('/BuildingDetails', { tab: 'Certification', id: data.context.id });
                break;
            }
            case notificationTypes.promoCodeAdded:
            case notificationTypes.promoCodeEdited:
            case notificationTypes.invoicePayment:
            case notificationTypes.invoiceReminder: {
                history.push('/BuildingDetails', {
                    tab: 'Invoice',
                    id: data.context.building.id
                });
                break;
            }
            default:
                history.push('/Home');
        }
    }
};

export const testJSON = (strJson: any) => {
    try {
        const parsed = JSON.parse(strJson);
        if (parsed && typeof parsed === 'object') {
            return true;
        }
    } catch {
        return false;
    }
    return false;
};

export const getCommaSeparatedString = (value: string | number) => {
    const num = value?.toString()?.split('.');
    if (num && num[0])
        num[0] = num[0]
            .split(',')
            .join('')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return num?.join('.');
};
